<template>
	<div class="page container">
		<v2-back-btn :route="this.getCourseRoute" />
		<div class="page__info">
			<div class="page__info--title">
				Students
			</div>
		</div>

		<table-view
			:table="getTable"
			:pagination="pagination"
			:totalItems="totalItems"
			@search="onSearch"
			@paginate="onPaginate"
		/>

	</div>
</template>

<script>
	import TableView     from '@/components/ui/views/tableView/TableView';
	import routeParams   from '@/mixins/routeParams';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Students'
		},
		components: {
			TableView,
			V2BackBtn
		},
		mixins: [routeParams],
		data: () => ({
			students: [],
			searchString: '',
			pagination: {
				perPage: 20,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		computed: {
			getTable () {
				if (!this.getCourseId) {
					return false;
				}
				return {
					headings: [
						'Name',
						'Joined'
					],
					rows: this.students.map((student) => {
						return {
							route: `/admin/courses/${this.getCourseId}/students/${student.id}`,
							columns: [
								this.getNameString(student),
								this.getJoinDateString(student)
							]
						};
					})
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: 'Course'
					},
					{
						path: `${this.getLessonRoute}/students`,
						text: 'Students'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setStudents();
		},
		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setStudents();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},
			getNameString (student) {
				if (!student) {
					return false;
				}
				return `${student.firstName} ${student.lastName}`;
			},
			getJoinDateString (student) {
				if (!student) {
					return false;
				}
				return dayjs().to(student.joinDate);
			},
			async setStudents () {
				const response = await api.admin.getStudentsByCourseId({
					courseId: this.getCourseId,
					include: 'metadata',
					searchString: this.searchString,
					per: this.pagination.perPage,
					page: this.pagination.currentPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.students = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
